.music {
    margin-top: 7rem;
    margin-bottom: 3rem;
    height: 100%;
    width: 100%;
    padding: 1rem;

    &__nav-active {
        border-bottom: 3px solid #000;
    }

    &__toggle {
        padding: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-transform: uppercase;

        h1 {
            cursor: pointer;

            &:nth-child(1) {
                margin-right: 1rem;
            }
    
            &:nth-child(2) {
                margin-left: 1rem;
            }
        }

    }


    &__grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(auto, auto);
        grid-gap: 10px;
        
        img {
            width: 100%;
        }
    }

    .hide {
        display: none;
    }

    &__album-covers, &__single-covers {
        position: relative;
        animation: fade 650ms ease-in-out;
    }

    &__album-links, &__single-links {
        width: 0;
        opacity: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 997;
        background: rgba(0,0,0,0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        transition: all 150ms ease-in-out;

        li {
            margin-bottom: .5rem;
        }

        a:link, a:visited {
            color: #fff;
            font-weight: 700;
        }
    }

    &__album-covers:hover &__album-links, &__single-covers:hover &__single-links {
        width: 100%;
        opacity: 1;
    }

}

@media (max-width: 750px) {
    .music {
        &__grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}