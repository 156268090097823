@mixin static {
    width: 100%;
    background-image: url('../images/background/texture.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.home {
    height: 100vh;
    margin-top: 7rem;
    perspective: 2px;
    overflow-x: hidden;
    overflow-y: auto;
    background: #000;

    &__section {
        width: 100%;
        display: block;
        position: relative;
    }

    &__banner1 {
        height: 100vh;
    }

    &__banner2 {
        height: 70vh;
    }

    &__banner3 {
        height: 70vh;
    }

    &__static {
        @include static;

        .home__static-content {
            width: 40%;
            margin-left: 2rem;

            h1 {
                text-transform: uppercase;
            }

            a:link, a:visited {
                z-index: 999;
                position: relative;
                display: block;
                color: #000;
                padding: .5rem;
                transition: all 200ms ease-in-out;
            }

            a:link:hover, a:visited:hover {
                color: #fff;
            }

            h2 {
                width: 12rem;
                background: transparent;
                border: 3px solid #000;
                font-size: 1.1rem;
                font-weight: 700;
                text-transform: uppercase;
                position: relative;
                margin: 0 auto;
                z-index: 999;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 100%;
                    background: #000;
                    transition: all 200ms ease-in-out;
                }

                &:hover::after {
                    width: 100%;
                }
            }
        }

        .home__static-image {
            max-width: 380px;
            min-width: 330px;

            img {
                width: 100%;
            }
        }
    }

    &__parallax::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        transform: translateZ(-1px) scale(1.6);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
    }

    &__banner1::after {
        background-image: url('../images/banners/banner1.jpg');
    }
    
    &__banner2::after {
        background-image: url('../images/banners/banner2.jpg');
        transform: translateZ(-1px) scale(2);
    }
    
    &__banner3::after {
        background-image: url('../images/banners/banner3.jpg');
    }

    &__video {
        @include static;

        .video-container {
            overflow: hidden;
            position: relative;
            width:47%;
        }
        
        .video-container::after {
            padding-top: 64.25%;
            display: block;
            content: '';
        }
        
        .video-container iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    
        .video-one {
            margin-right: 1rem;
        }
    
        .video-two {
            margin-left: 1rem;
        }
    }
}


@media (max-width: 750px) {

    .home {
        height: 100%;
    }

    .home__parallax::after {
        display: none;
    }

    .home__banner2, .home__banner3 {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .home__banner1 {
        display: none;
    }
    
    .home__banner2 {
        background-image: url('../images/banners/banner2.jpg');
    }
    
    .home__banner3 {
        background-image: url('../images/banners/banner3.jpg');
    }

    .home__static .home__static-content {
        width: 95%;
        margin: 0;
    }

    .home__video {
        display: flex;
        flex-direction: column;
    }

    .home__video .video-container {
        width: 100%;
        margin: 0;
    }

    .home__video .video-container:nth-child(1) {
        margin-bottom: 1.5rem;
    }
}