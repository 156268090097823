html {
  box-sizing: border-box;
}

*, 
*:before, 
*:after {
  box-sizing: inherit;
}

html, body {
  padding: 0;
  margin: 0;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#footer {
  margin-top: auto;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

a:link, a:visited {
  text-decoration: none;
}