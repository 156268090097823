.gallery {
    margin-top: 8rem;
    width: 100%;
    height: 100%;

    &__grid {
        display: grid;
        grid-template-columns: repeat((6, 1fr));
        grid-template-rows: repeat(9, 100px);
        width: 80%;
        max-width: 1000px;
        min-width: 700px;
        margin: 0 auto;
    }

    .one {
        grid-column: 1 / span 2;
        grid-row: 2 / span 1;
        transform: translateX(2rem);
    }
    .two {
        grid-column: 3 / span 3;
        grid-row: 2 / span 1;
        transform: translateY(-4rem);
    }
    .three {
        grid-column: 3 / span 1;
        grid-row: 1 / span 1;
        transform: translateX(-2rem);
    }
    .four {
        grid-column: 5 / span 2;
        grid-row: 5 / span 2;
        z-index: 2;
        transform: translateX(2rem);
        transform: translateY(-1rem);
        filter: grayscale(100%);
    }
    .five {
        grid-column: 3 / span 2;
        grid-row: 6 / span 2;
        transform: translateY(3rem);
        transform: translateX(3rem);
        filter: grayscale(100%);
    }
    .six {
        grid-column: 5 / span 2;
        grid-row: 1 / span 2;
    }
    .seven {
        grid-column: 1 / span 2;
        grid-row: 4 / span 2;
        transform: translateY(-2rem);
        filter: grayscale(100%);
        display: none;
    }
    .eight {
        grid-column: 4 / span 2;
        grid-row: 4 / span 2;
        transform: translateX(4rem);
        display: none;
    }
    .nine {
        grid-column: 1 / span 2;
        grid-row: 5 / span 1;
        z-index: 9;
        transform: translateX(2rem);
        filter: grayscale(100%);
    }

    &__image {
        transition: transform 500ms ease-in-out;
        img {
            width: 100%;
        }

        &:hover {
            transform: scale(1.3);
            z-index: 998;
        }
    }
}

@media (max-width: 700px) {
    .gallery {
        margin-top: 7.3rem;
        
        &__grid {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }

        .one, .two, .three, .four, .five, .six, .seven, .eight, .nine {
            transform: translateX(0);
            transform: translateY(0);
        }
    }
}