.contact {
    margin-top: 8rem;
    margin-bottom: 3rem;
    height: 100%;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__card {
        width: 60%;
        max-width: 800px;
        padding: 1rem;
        margin-top: 1rem;
        background-image: url('../images/background/texture.jpg');
        border-radius: 5px;
        display: flex;
        flex-direction: row;

        a:link, a:visited {
            position: relative;
            color: #000;
            font-weight: 700;
        }

        a:link::after, a:visited::after {
            content: '';
            display: block;
            width: 0;
            height: 2.5px;
            position: absolute;
            bottom: -.3rem;
            background: #000;
            transition: width .30s ease-in-out;
        }

        a:link:hover::after, a:visited:hover::after {
            width: 100%;
        }

        .contact__card-image {
            width: 30%;

            img {
                width: 100%;
            }
        }

        .cidida, .july {
            width: 30%;
        }

        .contact__card-content {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
    }
}

@media (max-width: 750px) {
    .contact {
        &__card {
            width: 80vw;
            display: flex;
            flex-direction: column;

            h3 {
                font-size: 1rem;
            }
        }

        .contact__card-image, .cidida, .july {
            width: 60%;
            margin: 0 auto;
        }
    }
}

