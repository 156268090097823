.landing {
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    position: fixed;
    top: 0;
    left: 0;
    background-image: url('../images/background/texture.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: scroll;

    &.hide {
        display: none;
    }

    &__cover {
        width: 60%;
        max-width: 450px;
        margin: 0 auto;

        img {
            width: 100%;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            position: relative;
            width: 15rem;
            background: transparent;
            border: 3px solid #000;
            font-size: 1.3rem;
            font-weight: 700;
            text-transform: uppercase;
            z-index: 999;
            margin-bottom: 1rem;

            a:link, a:visited {
                color: #000;
                display: block;
                padding: .5rem;
                position: relative;
                z-index: 999;
                transition: all 200ms ease-in-out;
            }

            a:link:hover, a:visited:hover {
                color: #fff;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background: #000;
                transition: all 200ms ease-in-out;
            }

            &:hover::after {
                width: 100%;
            }
        }
    }

    &__content {
        margin-top: 5rem;
        width: 60%;

        h3 {
            font-size: 1.4rem;
            text-align: center;
            color: #000;
            text-transform: uppercase;
        }
    }

    .close {
        width: 45px;
        height: 40px;
        position: absolute;
        top: 3rem;
        right: 2rem;
        cursor: pointer;

        &__bar {
            position: absolute;
            left: 0;
            width: 40px;
            height: 5px;
            background: #000;

            &:nth-child(1) {
                transform: rotate(45deg);
            }
            
            &:nth-child(2) {
                transform: rotate(-45deg);
            }
        }
    }
}

@media (max-width: 750px) {
    .landing__content {
        width: 100vw;
    }

    .landing__cover {
        width: 80%;
    }
}

@media (max-width: 400px) {
    .landing__content {
        width: 90vw;
        margin-top: 10rem;
    }
}