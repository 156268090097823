.footer {
    width: 100%;
    max-width: 100%;
    background: #000;
    text-align: center;
    text-transform: uppercase;
    z-index: 1000;
    color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    &__logo {
        width: 10rem;

        img {
            width: 100%;
        }
    }

    &__links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .list1 {
            margin-right: 1.5rem;
            margin-bottom: 0;
        }

        .list2 {
            margin-left: 1.5rem;
            margin-bottom: 0;
        }

        .footer__list-item {
            margin-bottom: .9rem;
        }

        a:link, a:visited {
            position: relative;
            font-weight: 700;
            display: block;
            color: #fff;
            font-size: 1.1rem;
            margin-bottom: .5rem;
        }

        a:link::after, a:visited::after {
            content: '';
            display: block;
            width: 0;
            height: 2.5px;
            position: absolute;
            bottom: -.3rem;
            background: #fff;
            transition: width .30s ease-in-out;
        }

        a:link:hover::after, a:visited:hover::after {
            width: 100%;
        }
    }

    &__contact {
        display: flex;
        flex-direction: column;

        h1 {
            margin-top: 10px;
            margin-bottom: 5px;
        }

        h2 {
            font-size: 1.2rem;
            margin: 0;
        }

        a:link, a:visited {
            color: #fff;
        }
    }
}

@media (max-width: 800px) {
    .footer {
        flex-direction: column;

        &__contact {
            margin-top: 2rem;
        }
    }
}